<!-- eslint-disable no-console -->
<script>
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */

import { mapActions, mapState, mapGetters } from "vuex";

/**
 * Autentição do usuário
 */
import authorize, {
  getQueryParam,
  getToken,
  currentUrlNeedsAuthentication,
  isSecureDomain,
  putStoreIdCookieOnLocalStorage,
} from "@/plugins/auth";
import { httpBasic } from "@/plugins/http";

/**
 * Google Trackking
 * $ga | $gtm
 */
import hotjar from "@/plugins/screenRecorder";
import trackGoogle from "@/plugins/google";
import PageView from "@/plugins/tray/page-view";
import AppIcon from "@/features/general/Icons";
import AppError from "@/features/general/Error";
import AppStoreLogo from "@/features/store-info/Logo";
import AppNavigation from "@/features/general/Navigation";
import AppStoreInstitutional from "@/features/store-info/Institutional";
import AppStoreCredits from "@/features/store-info/Credits";
import AppStoreContact from "@/features/store-info/Contact";
import AppStoreSecurity from "@/features/store-info/Security";
import AppSecureSeal from "@/features/store-info/SecureSeal";
import AppCustomerGreetings from "@/domains/Customer/components/greetings";
import AppCustomizer from "@/features/general/Customizer/main";
import validadeAmbient from "@/mixins/validadeAmbient";
import { logout } from "./plugins/auth";

export default {
  name: "App",

  mixins: [validadeAmbient],

  components: {
    AppIcon,
    AppError,
    AppStoreLogo,
    AppNavigation,
    AppStoreInstitutional,
    AppStoreCredits,
    AppStoreContact,
    AppStoreSecurity,
    AppCustomerGreetings,
    AppCustomizer,
    AppSecureSeal,
  },

  beforeCreate() {
    putStoreIdCookieOnLocalStorage();

    /**
     * Verifica se a url que esta acessando a loja é segura, caso não seja ocorre o redirecionamento
     * para a url segura.
     */
    isSecureDomain()
      .then((response) => {
        const { isSecure, url } = response.data;
        if (!isSecure) {
          window.location.replace(url);
        }
      })
      .catch((error) => {
        throw error;
      });

    /**
     * Autoriza para então atualizar os dados da loja
     */
    authorize()
      .then((response) => {
        this.setStoreInfo({ list: response.data });
        this.setTitle();
        this.setFavicon();
        this.setLanguageMetaTags();
        this.listenResize();
        this.setLangs({ list: response.data.langs });
        this.setCustomerData({ list: response.data.customer });

        // @to-do criar uma action setMenuState()
        this.$bus.on("toggle-menu", () => {
          this.toggleMenu();
        });

        if (this.canGeneratePlataformToken()) {
          this.generatePlataformToken();
          this.generateStoreFrontToken();
        }

        if (this.allowExternalScripts()) {
          this.includeIntegrations({ ...this.storeIntegrations });
          this.clearUrl();
        }

        if (!this.generalError) {
          this.setLoading({ loading: false });
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  mounted() {
    this.setIsMobile(window.innerWidth);
  },

  beforeUnmount() {
    window.removeEventListener("resize");
  },

  data() {
    return {
      activeMenu: false,
    };
  },

  // Propriedades
  computed: {
    ...mapState({
      list: (state) => state.store_info.list,
      loading: (state) => state.loading,
    }),

    ...mapGetters([
      "storeInfo",
      "storeIntegrations",
      "generalError",
      "isMobile",
      "langs",
    ]),

    /**
     * Valida se a URL do site é igual a URL da loja
     * @return {boolean}
     */
    isStoreUrlEqualOrigin() {
      return window.location.origin === this.list.uri.https;
    },

    /**
     * Define as propriedades para o componente storeName
     * @return {object}
     */
    storeLogoList() {
      const list = {
        logo: this.list.logo,
        name: this.list.name,
        uri: this.list.uri,
      };

      return list;
    },

    /*
     * Define as propriedades para o componente storeName
     * @return {object}
     */
    creditsList() {
      const list = {
        address: this.list.address,
        company: this.list.company,
        email: this.list.email,
        phone: this.list.phone,
        texts: this.list.texts,
      };

      return list;
    },

    /**
     * Verifica se o a loja possui algum
     * estilo personalizado
     * @return {boolean}
     */
    hasStyles() {
      return Object.keys(this.list.styles).length;
    },

    /**
     * Verifica se os dados da rota já foram carregados
     * @return {boolean}
     */
    hasLoadedRouteData() {
      return this.$route.name !== undefined;
    },

    /**
     * Valida se a toggle para exibir os links institucionais no roda pé está ativa
     * @return {boolean}
     */
    showInstitutionalLinks() {
      return (
        !this.loading &&
        this.hasInstitutionalPages &&
        this.showProductsAfterLogin
      );
    },

    /**
     * Valida de a loja exibe os produtos apenas após o login
     * @return {bool}
     */
    showProductsAfterLogin() {
      return this.list.settings.store.login_product === "1";
    },

    /**
     * Valida se a loja tem páginas institucionais
     * @return {boolean}
     */
    hasInstitutionalPages() {
      return this.storeInfo?.custom_pages?.data?.length > 0;
    },
  },

  methods: {
    ...mapActions([
      "setStoreInfo",
      "setLoading",
      "setIsMobile",
      "setCustomStyle",
      "setGeneralError",
      "setLangs",
    ]),

    ...mapActions("Customer", {
      setCustomerData: "setData",
    }),

    ...mapActions("Address", {
      setAddressesData: "setData",
    }),

    /**
     * Valida se é necessário gerar o token da plataforma
     * @return {boolean}
     */
    canGeneratePlataformToken() {
      const hasPlataformToken =
        localStorage.getItem("hasPlataformToken") === "true";

      if (
        hasPlataformToken ||
        this.isTestAmbient ||
        !currentUrlNeedsAuthentication()
      ) {
        return false;
      }

      if (!this.isStoreUrlEqualOrigin) {
        return false;
      }

      return true;
    },

    /**
     * Chamando uma das urls do legado somente para fazer a geração de token
     * @return {undefined}
     */
    async generatePlataformToken() {
      const token = getToken();

      const isValidToken = await this.isValidToken(token);
      if (!isValidToken) {
        this.logoutAndRedirectToLogoutPlatform();
        return;
      }

      try {
        const path = `/loja/central_comentarios.php?token=${token}`;
        await httpBasic.get(path);
        localStorage.setItem("hasPlataformToken", "true");
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * Valida o token que será enviado para plataforma
     * @return {boolean}
     */
    async isValidToken(token) {
      try {
        const path = `/validate-token`;
        const {data} = await this.$http.post(path, { token });
        if (!data) {
          throw new Error("Token inválido");
        }
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    /**
     * Redireciona o usuario para pagina de logout da plataforma
     */
    logoutAndRedirectToLogoutPlatform() {
      logout();
      window.location.replace(
        `${window.location.origin}/loja/logout.php?loja=${this.storeInfo.id}`
      );
    },

    /**
     * Faz a chamada para criação da sessão do StoreFront
     * @return {undefined}
     */
    async generateStoreFrontToken() {
      try {
        const path = `/session_auth`;
        const body = {
          token: getToken(),
        };

        await httpBasic.post(path, body);
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * Altera o titulo da aplicação
     * @returns {undefined}
     */
    setTitle() {
      document.title = `${this.storeInfo.name} | ${document.title}`;
    },

    /**
     * Adiciona o favicon da loja
     * @returns {undefined}
     */
    setFavicon() {
      if (this.storeInfo.favicon?.https) {
        const faviconElement = document.createElement("link");
        faviconElement.setAttribute("rel", "icon");
        faviconElement.setAttribute("type", "image/x-icon");
        faviconElement.setAttribute("href", this.storeInfo.favicon.https);

        document.querySelector("head").appendChild(faviconElement);
      }
    },

    /**
     * Define na página o atributo lang do html e a meta tag de acordo com o idioma da loja
     * @return {undefined}
     */
    setLanguageMetaTags() {
      const languages = {
        pt_BR: "pt-BR",
        en: "en-US",
        es: "es-ES",
      };

      const currentLanguage = languages[this.storeInfo.settings.store.lang];

      try {
        document.querySelector("html").setAttribute("lang", currentLanguage);
        document
          .querySelector('meta[http-equiv="content-language"]')
          .setAttribute("content", currentLanguage);
      } catch {
        return;
      }
    },

    /**
     * Limpa as querys da URL ex: ?token=xxx
     * @returns {undefined}
     */
    clearUrl() {
      if (this.isTestAmbient) {
        return;
      }

      const url = new URL(window.location.href);

      const hasParam = url.search.length > 0;
      if (hasParam) {
        this.$router.replace({ query: {} });
      }
    },

    /**
     * Sempre que a tela for redimensionda
     * @state->isMobile é definido
     * @returns {undefined}
     */
    listenResize() {
      window.addEventListener("resize", () => {
        this.setIsMobile(window.innerWidth);
      });
    },

    /**
     * Exibe/Esconde o menu no ambiente mobile
     * @returns {undefined}
     */
    toggleMenu() {
      this.activeMenu = !this.activeMenu;
    },

    /**
     * Efetua o rastreamento de navegação do usuário e envio os dados coletados ao PageView.
     * @returns {undefined}
     */
    trackPageView() {
      PageView.send(this.$route.name);
    },

    /**
     * Carrega os scripts externos cadastrados no painel da loja
     * @param { object } storeIntegrations
     */
    includeIntegrations({ google = {}, chat = "" } = {}) {
      const { store: storeSettings } = this.storeInfo.settings;
      const ga4Code = storeSettings["google_analytics_v4"];

      trackGoogle({
        googleIntegrations: google,
        storeInfo: this.list,
        ga4Code,
      });
      this.includeChat(chat);
      this.screenRecorder();

      PageView.init(this.list.id);
      this.trackPageView();
    },

    /**
     * Inicializa a gravação da tela do usúario
     * @param { object } storeIntegrations
     */
    screenRecorder() {
      const { features } = this.storeInfo;
      if (features.indexOf("screen-recorder") === -1) {
        return;
      }

      hotjar();
    },

    /**
     * Inclui o script de chat caso a loja possua um
     * @param { string } chat
     * @return {undefined}
     */
    includeChat(chat) {
      if (!chat) {
        return;
      }

      if (chat.indexOf("document.write") !== -1) {
        console.warn(
          "A função document.write presente no seu script de chat pode prejudicar o carregamento da aplicação, por isto seu script não foi carregado"
        ); // eslint-disable-line no-console
        return;
      }

      const content = document.createElement("div");
      content.setAttribute("id", "chat-container");

      content.innerHTML = chat;
      document.body.appendChild(content);
    },

    /**
     * Verifica se a aplicação está autorizada a carregar
     * scripts externos
     * @return {boolean}
     */
    allowExternalScripts() {
      return getQueryParam(window.location.href, "js") !== "0";
    },
  },

  watch: {
    /**
     * Caso o usuario altere a rota
     * o menu irá fechar automaticamente
     */
    $route() {
      this.trackPageView();
      if (!this.activeMenu) {
        return;
      }
      this.toggleMenu();
    },
  },
};
</script>

<template>
  <section>
    <app-customizer :styles="this.list.styles" v-if="hasStyles" />

    <header class="app__header" v-show="hasLoadedRouteData">
      <div class="container app__header__container">
        <app-store-logo
          class="app__header__container-logo"
          :list="storeLogoList"
          :loading="loading"
        />
        <div
          class="app__header__container-info"
          v-if="this.$route.meta.requiresAuth"
        >
          <app-customer-greetings class="app__header__greetings" />
          <figure
            class="app__header__menu-icon d-block d-md-none"
            :class="{ app__loading: loading }"
            v-on:click="toggleMenu()"
          >
            <app-icon name="bars" prefix="fa" />
          </figure>
        </div>
        <app-secure-seal v-else :loading="loading" position="header" />
      </div>
    </header>

    <section class="container app__container">
      <div class="row">
        <aside
          v-show="this.$route.meta.requiresAuth"
          class="col-md-3 app__sidebar"
        >
          <transition
            name="slide-menu"
            :enter-active-class="
              isMobile
                ? 'app__animated--slide-in-right'
                : 'app__animated--slide-in-left'
            "
            leave-active-class="app__animated--slide-out-right"
          >
            <app-navigation
              class="app__navigation"
              v-show="this.activeMenu || !this.isMobile"
            >
            </app-navigation>
          </transition>
        </aside>
        <article
          class="app__content app__animated--slide-in-down"
          :class="this.$route.meta.requiresAuth ? 'col-md-9' : 'col-md-12'"
        >
          <router-view v-if="!this.generalError" v-slot="{ Component }">
            <transition
              name="fade-content"
              appear
              enter-active-class="app__animated--fade-in"
              leave-active-class="app__animated--fade-out"
              mode="out-in"
            >
              <component :is="Component" />
            </transition>
          </router-view>
          <app-error v-else />
        </article>
      </div>
    </section>

    <footer class="app__footer" v-show="hasLoadedRouteData">
      <div class="app__footer__container">
        <div class="app__footer__container-wrapper">
          <div
            class="app__footer__block app__footer__block--auto app__footer__block--shade"
            v-if="showInstitutionalLinks"
          >
            <app-store-institutional :loading="loading" />
          </div>

          <div class="app__footer__divider" v-if="showInstitutionalLinks" />

          <div
            class="app__footer__block app__footer__block--auto app__footer__block--shade"
          >
            <h2 class="app__footer__title" :class="{ app__loading: loading }">
              {{ this.langs.pagination["customer_service"] }}
            </h2>
            <app-store-contact :loading="loading" />
          </div>

          <div class="app__footer__divider" />

          <div class="app__footer__block app__footer__block--auto">
            <h2 class="app__footer__title" :class="{ app__loading: loading }">
              {{ this.langs.pagination["security"] }}
            </h2>
            <app-store-security :loading="loading" />
          </div>

          <div class="app__footer__block app__footer__block--full">
            <app-store-credits :list="creditsList" :loading="loading" />
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<style lang="scss">
@import "@/assets/sass/app.scss";
</style>
